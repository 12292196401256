<template>
  <v-app>
    <v-main>
      <!--   top header   -->
      <main-header />

      <div class="page-slot">
        <slot />
      </div>

      <main-footer />
    </v-main>
  </v-app>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.page-slot {
  flex: 1 1 auto;
  min-height: 1100px;
  height: 100%;
}
</style>
